import { HomeConst } from '../constants';
import { HomeService } from '../services';

function fetchPaymentData(id) {
    // return the promise using fetch which adds to localstorage on resolve

    function request() {
        return {type: HomeConst.FETCH_PAYMENT_DATA_REQUEST}
    }

    function success(payload) {
        return {type: HomeConst.FETCH_PAYMENT_DATA_SUCCESS, payload}
    }

    function failure(payload) {
        return {type: HomeConst.FETCH_PAYMENT_DATA_FAILURE, payload}
    }

    return async function dispatch(dispatch) {

        dispatch(request());

        try {
            const res = await HomeService.fetchPaymentData(id);

            if (res.status && res.data) {
                dispatch(success(res));
            } else {
                dispatch(failure(res));
            }
        } catch (err) {
            const error = await err;
            dispatch(failure(error));
        }
    }
}

function generatePaymentLink(id, name, email) {
    // return the promise using fetch which adds to localstorage on resolve

    function request() {
        return { type: HomeConst.GENERATE_PAYMENT_LINK_REQUEST }
    }

    function success(payload) {
        return { type: HomeConst.GENERATE_PAYMENT_LINK_SUCCESS, payload }
    }

    function failure(payload) {
        return { type: HomeConst.GENERATE_PAYMENT_LINK_FAILURE, payload }
    }

    return async function dispatch(dispatch) {

        dispatch(request());

        try {
            const res = await HomeService.generatePaymentLink(id, name, email);

            if (res.status && res.data) {
                dispatch(success(res));
            } else {
                dispatch(failure(res));
            }
        } catch (err) {
            const error = await err;
            dispatch(failure(error));
        }
    }
}

export const HomeAction = {
    fetchPaymentData,
    generatePaymentLink
};
